import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';

//Custom
import ButtonActionForm from '../Form/elements/ButtonActionForm';
import InputFieldWithValidation from '../Form/elements/InputFieldWithValidation';
import SelectFieldWithValidation from '../Form/elements/SelectFieldWithValidation';
import UploadFileWithPreview from '../Form/elements/UploadFileWithPreview';
import SmartForm from '../Form/SmartForm';
import languages from '../../__fixtures__/languages';

//Api
import { createConsumer } from '../../api/utils/consumer/setAdminConsumer';
import { useHistory } from 'react-router-dom';
import UserAppsInput from './UserAppsInput';
import SelectRole from './SelectRole';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import { getMerchantsPreviewsList } from '../../api/utils/merchant/getMerchantList';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';
import Icon from '../UI/Icon';
import UserInfoContext from '../../contexts/UserInfoContext';
import ConsumerInfoContext from '../../contexts/ConsumerInfoContext';
import _ from 'lodash';
import RolesSelector from './RolesSelector';
import BackButton from '../UI/BackButton';

export default function UserCreate() {
    const { app, merchant, setInfo } = useContext(UserInfoContext);
    const { is_superadmin, role } = useContext(ConsumerInfoContext);

    const [selectedApps, setSelectedApps] = useState([]); // Uses `appList` as options
    const [selectedMerchants, setSelectedMerchants] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [roles, setRoles] = useState([]);
    const [loadingRoles, setLoadingRoles] = useState(false);

    const history = useHistory();
    let timezones = moment.tz.names(); //To get list of timezone

    useEffect(() => {
        if (app?.id && merchant?.id) {
            fetchRoles();
        }
    }, [app, merchant]);

    const fetchRoles = async () => {
        const resultHandler = (res) => {
            setLoadingRoles(false);
            if (res?.status === 'accept' && res?.roles?.length) {
                setRoles(res.roles);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setLoadingRoles(false);
            setRoles([]);
        };

        try {
            setLoadingRoles(true);
            const res = await getAdminRolesList(
                {
                    merchant: merchant.id,
                    appid: app.id,
                    query_skip: 0,
                    query_count: 10000,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            setLoadingRoles(false);
            setRoles([]);
            console.log('error fetching roles: ', error);
        }
    };

    const prepareAppsData = () => {
        if (is_superadmin) {
            const newApps = {
                apps: selectedApps,
                merchants: selectedMerchants,
                roles: selectedRoles.map((item) => item._id),
            };
            return newApps;
        }

        if (!selectedRoles?.length)
            return { apps: [], merchants: [], roles: [] };
        try {
            const apps = {
                apps: [app.id],
                merchants: [merchant.id],
                roles: selectedRoles.map((item) => item._id),
            };
            return apps;
        } catch (error) {
            return { apps: [], merchants: [], roles: [] };
        }
    };

    const onSubmit = async (val) => {
        const appsData = prepareAppsData();

        if (_.isEmpty(appsData?.apps)) {
            NotificationManager.error('Please, select user apps');
            return;
        }
        if (_.isEmpty(appsData?.merchants)) {
            NotificationManager.error('Please, select user merchants');
            return;
        }
        if (_.isEmpty(appsData?.roles)) {
            NotificationManager.error('Please, select user roles');
            return;
        }

        const dataOption = {
            email: val.email,
            settings: val,
        };
        if (appsData) dataOption.apps = appsData;

        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('User Created', 'Success', 4000);
                history.push('/users');
            } else {
                NotificationManager.error('Unknown error', 'Error', 4000);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        console.log('dataOption == ', dataOption);
        try {
            const res = await createConsumer(dataOption, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log('error creating user: ', error);
        }
    };

    return (
        <div>
            <NotificationContainer />
            <SmartForm onValid={() => {}} onSubmit={onSubmit}>
                <h3>
                    <BackButton to="/users" text="Back to users list" /> New
                    User
                </h3>
                <hr />
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <UploadFileWithPreview
                                name="str_photo_data"
                                style={{ maxHeight: '100px' }}
                                type="text"
                            />
                        </div>
                        <div className="col col-10 mb-3">
                            {is_superadmin ? (
                                <RolesSelector
                                    selectedApps={selectedApps}
                                    setSelectedApps={setSelectedApps}
                                    selectedMerchants={selectedMerchants}
                                    setSelectedMerchants={setSelectedMerchants}
                                    selectedRoles={selectedRoles}
                                    setSelectedRoles={setSelectedRoles}
                                    disableFinal={true}
                                />
                            ) : (
                                <SelectRole
                                    roles={roles}
                                    selected={selectedRoles}
                                    onChange={(val) => setSelectedRoles(val)}
                                    required={true}
                                    loading={loadingRoles}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2" />
                        <div className="col-5">
                            <InputFieldWithValidation
                                className="mb-3"
                                label="Email"
                                name="email"
                                required
                            />
                            <InputFieldWithValidation
                                className="mb-3"
                                label="First name"
                                name="str_firstname"
                                required
                            />
                            <InputFieldWithValidation
                                className="mb-3"
                                label="Last name"
                                name="str_lastname"
                                required
                            />
                            <InputFieldWithValidation
                                className="mb-3"
                                label="Telephone"
                                name="str_telephone"
                            />
                            <SelectFieldWithValidation
                                className="mb-3"
                                label="Language"
                                name="str_language"
                                options={languages}
                            />
                            <SelectFieldWithValidation
                                className="mb-3"
                                label="Timezone"
                                options={timezones}
                                name="str_timezone"
                            />
                            <InputFieldWithValidation
                                className="mb-3"
                                label="Ssn"
                                name="str_ssn"
                            />
                        </div>
                        <div className="col-5">
                            <InputFieldWithValidation
                                className="mb-3"
                                label="Street Address"
                                name="str_address1"
                            />
                            <InputFieldWithValidation
                                className="mb-3"
                                label="Address additional"
                                name="str_address2"
                            />
                            <InputFieldWithValidation
                                className="mb-3"
                                label="City"
                                name="str_city"
                            />
                            <InputFieldWithValidation
                                className="mb-3"
                                label="Zip"
                                name="str_zip"
                            />
                            <SelectFieldWithValidation
                                className="mb-3"
                                label="Country"
                                name="str_country"
                                options={['ENG', 'RUS', 'FIN']}
                            />
                        </div>
                    </div>
                </div>
                <ButtonActionForm
                    label="Save changes"
                    name="active"
                    value={true}
                    type="submit"
                    className="btn btn-primary mt-2"
                />
            </SmartForm>
        </div>
    );
}
