import React, { useEffect, useState, useContext } from 'react';

import {
    Container,
    Form,
    Button,
    InputGroup,
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import moment from 'moment-timezone';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import PaymentsOverview from './PaymentsOverview';
import getServerInfo from '../../api/utils/server/getServerInfo';

import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';

import { City } from 'country-state-city';

import cn from 'classnames';

import { getFileList } from '../../api/utils/files/getFileList';
import RegistrationStatusContext from '../../contexts/RegistrationStatusContext';

import renderCurrency from '../../utils/renderCurrency';
import setDecimals from '../../utils/setDecimals';
import ConfirmModal from '../common/ConfirmModal';
import ConsumerInfoContext from '../../contexts/ConsumerInfoContext';
import RolesMapperForm from './components/RolesMapperForm';

const BANKS_LIST = [
    'Nordea',
    'Op',
    'S-Pankki',
    'Danske',
    'Saastop',
    'POP-Pankki',
    'Akti',
    'Omasp',
    'AlandsBanken',
    'Handelsbanken',
];

const BANKS_LIST_OPTIONS = BANKS_LIST.map((item) => ({
    label: item,
    value: item,
}));
BANKS_LIST_OPTIONS.push({ label: 'Select', value: '' });

const MerchantInfoForm = ({
    payments,
    merchantInfo,
    onSubmit = () => {},
    onDelete = () => {},
}) => {
    let timezones = moment.tz.names();

    const [merchant, setMerchant] = useState(merchantInfo);

    const [countries, setCountryList] = useState();
    const [country, setCountry] = useState();
    const [cities, setCitiesList] = useState([]);

    const [fileList, setFileList] = useState([]);
    const [fileListOptions, setFileListOptions] = useState([]);

    const regStatus = useContext(RegistrationStatusContext);
    const { is_superadmin } = useContext(ConsumerInfoContext);

    const [deleteInitiated, setDeleteInitiated] = useState(false);

    const getCountryISO2 = require('country-iso-3-to-2');

    // TODO: update roles_mapper field on `merchant` state
    // Then change it also on merchant
    // on submit - map it back to dict with values as list of strings
    useEffect(() => {
        if (
            !_.isEmpty(merchantInfo?.settings?.roles_mapper) &&
            Object.keys(merchantInfo.settings.roles_mapper).length
        ) {
            let newMappings = Object.entries(
                merchantInfo.settings.roles_mapper
            ).map(([key, value], idx) => {
                return {
                    id: uuidv4(),
                    key,
                    value,
                };
            });
            setMerchant({
                ...merchantInfo,
                settings: {
                    ...merchantInfo.settings,
                    roles_mapper: newMappings,
                },
            });
        }
    }, [merchantInfo]);

    useEffect(() => {
        if (countries && countries.length > 0) {
            if (!merchant.settings?.str_country) {
                setMerchant({
                    ...merchant,
                    settings: {
                        ...merchant.settings,
                        str_country: countries[0].value,
                    },
                });
            }
        }
    }, [countries]);

    const fetchServerInfo = async () => {
        const resultHandler = (res) => {
            if (
                res &&
                res?.status === 'accept' &&
                res?.server?.server?.country?.length
            ) {
                setCountryList(
                    res.server.server.country.map((item) => ({
                        name: item.name,
                        value: item.iso,
                    }))
                );
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(
                error.description,
                'Error getting server info',
                4000
            );
        };

        try {
            const res = await getServerInfo({}, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchServerInfo();
    }, []);

    useEffect(() => {
        let cities = City.getCitiesOfCountry(getCountryISO2(country));
        setCitiesList(cities);
    }, [country]);

    useEffect(() => {
        let cities = City.getCitiesOfCountry(
            getCountryISO2(merchant.settings.str_country)
        );
        setCitiesList(cities);
    }, [merchant]);

    const onBusinessIdFieldLostFocus = async (data) => {
        const INFO_URL = `https://avoindata.prh.fi/bis/v1/${data.target.value.trim()}`;
        let response = await fetch(INFO_URL);
        let result = await response.json();
        if (result.results.length > 0) {
            //todo what data should be collected? onBusinessDataCollected(result.results[0])
            setMerchant({
                ...merchant,
                settings: {
                    ...merchant.settings,
                    str_name: result.results[0].name,
                },
            });
        } else {
            NotificationManager.error(
                response.error_message || 'Business id not found',
                'Error',
                5000
            );
        }
    };

    useEffect(() => {
        if (country) {
            let cities = City.getCitiesOfCountry(getCountryISO2(country));
            setCitiesList(cities);
        }
    }, [country]);

    const fetchAssetFiles = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setFileList(data.files);
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setFileList([]);
        };

        getFileList(
            { type: 'image', object: merchant?.application_id },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };
    useEffect(() => {
        fetchAssetFiles();
    }, [merchant?.application_id]);

    useEffect(() => {
        const defaultOpt = {
            url: -1,
            display_name: 'Select logo image',
        };
        if (fileList.length) {
            setFileListOptions([...fileList, defaultOpt]);
        } else {
            setFileListOptions([defaultOpt]);
        }
    }, [fileList]);

    /*
  Backend fields of Merchant model that are missing in form
    application_id: Optional[PyObjectId] = None
    is_default: bool = False
    parent: PyObjectId = None
    registration: PyObjectId = None
    base16: str = Field(default_factory=get_base16)
    admin_role: PyObjectId = None
    currency: str = "EUR"
    IBAN_Number: str = None
    Merchant_balance: str = None
    Minumium_balance: str = None
  */

    const hanldeSubmit = (e) => {
        e.preventDefault();

        const submitMerchant = _.cloneDeep(merchant);

        submitMerchant.billing_start_date = moment(
            submitMerchant.billing_start_date
        ).format('YYYY-MM-DD');

        const submitRoleMappings = {};
        if (_.isEmpty(submitMerchant?.settings?.roles_mapper)) {
            submitMerchant.settings.roles_mapper = [];
        }
        submitMerchant.settings?.roles_mapper?.forEach((item) => {
            submitRoleMappings[item?.key] = item?.value;
        });
        submitMerchant.settings.roles_mapper = submitRoleMappings;

        onSubmit(submitMerchant);
    };

    const handleChangeMerchantField = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setMerchant((prev) => {
            const newMerchant = _.cloneDeep(prev);
            _.set(newMerchant, name, value);
            return newMerchant;
        });
    };

    const handleInitDelete = () => {
        setDeleteInitiated(true);
    };

    const handleConfirmDelete = () => {
        setDeleteInitiated(false);
        onDelete();
    };

    const handleAddRoleMapper = () => {
        const newRolesMapper = merchant?.settings?.roles_mapper?.length
            ? merchant.settings.roles_mapper
            : [];
        setMerchant((prev) => ({
            ...prev,
            settings: {
                ...prev.settings,
                roles_mapper: [
                    ...newRolesMapper,
                    { id: uuidv4(), key: '', value: [] },
                ],
            },
        }));
    };

    const handleRemoveRoleMapper = (mappingId) => {
        setMerchant((prev) => {
            let newRolesMapper = prev?.settings?.roles_mapper?.length
                ? prev.settings.roles_mapper
                : [];

            newRolesMapper = newRolesMapper.filter(
                (item) => item.id !== mappingId
            );

            return {
                ...prev,
                settings: {
                    ...prev.settings,
                    roles_mapper: newRolesMapper,
                },
            };
        });
    };

    return (
        <Container fluid>
            <NotificationContainer />
            <Row>{payments ? <PaymentsOverview {...payments} /> : null}</Row>

            <Row>
                <Col xs={12}>
                    <h4>Merchant info</h4>
                </Col>

                {!regStatus?.result && !is_superadmin ? (
                    <Col xs={12}>
                        <span className="text-muted">
                            Some features are only available after registration
                            is complete
                        </span>
                    </Col>
                ) : null}
            </Row>

            <Form onSubmit={hanldeSubmit}>
                {regStatus?.result || is_superadmin ? (
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Check
                                type="switch"
                                id="active"
                                name="active"
                                label="Active"
                                checked={!!merchant.active}
                                onChange={(e) =>
                                    handleChangeMerchantField({
                                        target: {
                                            name: 'active',
                                            value: e.target.checked,
                                        },
                                    })
                                }
                            />
                            <Form.Check
                                type="switch"
                                id="test_mode"
                                name="test_mode"
                                label="Test mode"
                                checked={!!merchant.test_mode}
                                onChange={(e) =>
                                    handleChangeMerchantField({
                                        target: {
                                            name: 'test_mode',
                                            value: e.target.checked,
                                        },
                                    })
                                }
                            />
                        </Col>
                    </Row>
                ) : null}

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_businessid">
                            <Form.Label>Business ID</Form.Label>
                            <Form.Control
                                name="settings.str_businessid"
                                type="text"
                                value={merchant.settings.str_businessid}
                                onChange={handleChangeMerchantField}
                                onBlur={onBusinessIdFieldLostFocus}
                                placeholder="1234567-8"
                                required
                            />
                            <Form.Text className="text-muted">
                                Business Identity Code given by the PRH or the
                                Tax Administration
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="settings.str_name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="settings.str_name"
                                type="text"
                                value={merchant.settings.str_name}
                                onChange={handleChangeMerchantField}
                                placeholder="Merchant101"
                                required
                            />
                            <Form.Text className="text-muted">
                                Merchant's name
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="settings.str_vatnumber">
                            <Form.Label>Vat ID</Form.Label>
                            <Form.Control
                                name="settings.str_vatnumber"
                                type="text"
                                value={merchant.settings.str_vatnumber}
                                onChange={handleChangeMerchantField}
                                placeholder="FI12345678"
                                required
                            />
                            <Form.Text className="text-muted">
                                Business ID with country code prefix and withoud
                                dash
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="settings.str_telephone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                name="settings.str_telephone"
                                type="text"
                                value={merchant.settings.str_telephone}
                                onChange={handleChangeMerchantField}
                                placeholder="+358501234567"
                            />
                            <Form.Text className="text-muted">
                                Phone number
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="settings.str_email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                name="settings.str_email"
                                type="text"
                                value={merchant.settings.str_email}
                                onChange={handleChangeMerchantField}
                                placeholder="merchant@honkio.com"
                                required
                            />
                            <Form.Text className="text-muted">
                                Email address
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_email">
                            {/* TODO: Add proper input for loading images */}
                            <Form.Label>Logo</Form.Label>

                            <Container>
                                <Image
                                    src={
                                        merchant?.settings?.str_logo ||
                                        'https://placehold.co/600x400'
                                    }
                                    fluid
                                    alt="Logo"
                                />
                            </Container>

                            <Form.Control
                                as="select"
                                name="settings.str_logo"
                                value={merchant?.settings?.str_logo || ''}
                                onChange={handleChangeMerchantField}
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Choose...</option>
                                {fileListOptions.map((opt, i) => (
                                    <option
                                        key={`logo-option-${i}-${opt?._id}`}
                                        value={opt?.url}
                                    >
                                        {opt?.display_name ||
                                            opt?.name ||
                                            `Image ${i}`}
                                    </option>
                                ))}
                                <option>...</option>
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Select logo image
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.str_address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                name="settings.str_address1"
                                type="text"
                                value={merchant.settings.str_address1}
                                onChange={handleChangeMerchantField}
                                placeholder="101 Main street"
                            />
                            <Form.Text className="text-muted">
                                Address
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.str_address">
                            <Form.Label>
                                Address 2{' '}
                                <span className="text-muted">(optional)</span>
                            </Form.Label>
                            <Form.Control
                                name="settings.str_address2"
                                type="text"
                                value={merchant.settings.str_address2}
                                onChange={handleChangeMerchantField}
                                placeholder="APT 12"
                            />
                            <Form.Text className="text-muted">
                                Address, line two
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.str_country">
                            <Form.Label>Country</Form.Label>

                            <Form.Control
                                as="select"
                                name="settings.str_country"
                                value={merchant?.settings?.str_country || ''}
                                onChange={handleChangeMerchantField}
                                className={cn('custom-select', 'crsr')}
                                disabled={merchant?._id}
                            >
                                <option value="">Choose country...</option>
                                {countries?.length
                                    ? countries.map((opt, i) => (
                                          <option
                                              key={`country-option-${i}-${opt?._id}`}
                                              value={opt?.value}
                                          >
                                              {opt?.name}
                                          </option>
                                      ))
                                    : null}
                            </Form.Control>

                            <Form.Text className="text-muted">
                                3 letters ISO code
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.str_city">
                            <Form.Label>City</Form.Label>

                            <Form.Control
                                as="select"
                                name="settings.str_city"
                                value={merchant?.settings?.str_city || ''}
                                onChange={handleChangeMerchantField}
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Choose city...</option>
                                {cities?.length
                                    ? cities.map((opt, i) => (
                                          <option
                                              key={`city-option-${i}-${opt?._id}`}
                                              value={opt?.value}
                                          >
                                              {opt?.name}
                                          </option>
                                      ))
                                    : null}
                            </Form.Control>

                            <Form.Text className="text-muted">
                                City name
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.str_timezone">
                            <Form.Label>Timezone</Form.Label>

                            <Form.Control
                                as="select"
                                name="settings.str_timezone"
                                value={merchant?.settings?.str_timezone || ''}
                                onChange={handleChangeMerchantField}
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Choose timezone...</option>
                                {timezones?.length
                                    ? timezones.map((opt, i) => (
                                          <option
                                              key={`timezone-option-${i}-${opt}`}
                                              value={opt}
                                          >
                                              {opt}
                                          </option>
                                      ))
                                    : null}
                            </Form.Control>

                            <Form.Text className="text-muted">
                                Timezone
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    {merchant?._id ? (
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="settings.str_timezone">
                                <Form.Label>API key</Form.Label>

                                <Form.Control as="span" name="api_key">
                                    {merchant.api_key}
                                </Form.Control>

                                <Form.Text className="text-muted">
                                    merchant API key
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    ) : null}

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.str_address">
                            <Form.Label>Success URL</Form.Label>
                            <Form.Control
                                name="settings.telia_success_url"
                                type="text"
                                value={merchant.settings.telia_success_url}
                                onChange={handleChangeMerchantField}
                                placeholder="http://example.com/success"
                            />
                            <Form.Text className="text-muted">
                                Telia success URL
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    {regStatus?.result || is_superadmin ? (
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="settings.is_telia_enabled">
                                <Form.Label>Is Telia enabled</Form.Label>
                                <Form.Check
                                    type="switch"
                                    name="settings.is_telia_enabled"
                                    checked={
                                        merchant?.settings?.is_telia_enabled
                                            ?.toString()
                                            ?.toLowerCase() === 'true'
                                            ? true
                                            : false
                                    }
                                    onChange={(e) =>
                                        handleChangeMerchantField({
                                            target: {
                                                name: 'settings.is_telia_enabled',
                                                value: e.target.checked,
                                            },
                                        })
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Is Telia authentication functionality
                                    enabled
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    ) : null}

                    <Col xs={12} sm={6}>
                        <Form.Group
                            className="mb-3"
                            controlId="data.start_date"
                        >
                            <Form.Label>Billing start date</Form.Label>
                            <Form.Control
                                as={DatePicker}
                                aria-label="Billing start date"
                                name="billing_start_date"
                                selected={moment(
                                    merchant.billing_start_date
                                ).toDate()}
                                onChange={(date) =>
                                    handleChangeMerchantField({
                                        target: {
                                            value: date,
                                            name: 'billing_start_date',
                                        },
                                    })
                                }
                                required
                                showTimeSelect
                                dateFormat="dd-MM-yyyy HH:mm"
                                timeFormat="HH:mm"
                                placeholderText="Select date"
                            ></Form.Control>
                            <Form.Text className="text-muted">
                                Billing starts at
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="Bank_name">
                            <Form.Label>Bank name</Form.Label>

                            <Form.Control
                                as="select"
                                name="Bank_name"
                                value={merchant?.Bank_name || ''}
                                onChange={handleChangeMerchantField}
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Bank name...</option>
                                {BANKS_LIST_OPTIONS.map((opt, i) => (
                                    <option
                                        key={`bank-name-option-${i}-${opt?.label}`}
                                        value={opt?.value}
                                    >
                                        {opt?.label}
                                    </option>
                                ))}
                            </Form.Control>

                            <Form.Text className="text-muted">
                                Select the bank you're using
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="IBAN_Number">
                            <Form.Label>IBAN Number</Form.Label>
                            <Form.Control
                                name="IBAN_Number"
                                type="text"
                                value={merchant.IBAN_Number}
                                onChange={handleChangeMerchantField}
                                placeholder="FI7658689755766441"
                            />
                            <Form.Text className="text-muted">
                                Bank account number in IBAN format
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="Minimum_balance">
                            <Form.Label>Minumum balance</Form.Label>
                            <Form.Control
                                name="Minimum_balance"
                                type="text"
                                value={merchant.Minimum_balance}
                                onChange={handleChangeMerchantField}
                                placeholder={
                                    renderCurrency(merchant?.currency) + '1000'
                                }
                            />
                            <Form.Text className="text-muted">
                                Minimum balance amount,{' '}
                                {renderCurrency(merchant?.currency)}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <h4 className="mt-3">Encryption settings</h4>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.public_key">
                            <Form.Label>Public key</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="-----BEGIN PUBLIC KEY-----..."
                                name="settings.public_key"
                                value={merchant?.settings?.public_key}
                                onChange={handleChangeMerchantField}
                            />
                            <Form.Text className="text-muted">
                                generated Merchant ID sha256 public key
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.private_key">
                            <Form.Label>Private key</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="-----BEGIN PRIVATE KEY-----..."
                                name="settings.private_key"
                                value={merchant?.settings?.private_key}
                                onChange={handleChangeMerchantField}
                            />
                            <Form.Text className="text-muted">
                                generated Merchant ID sha256 private key
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <h4 className="mt-3">Payment settings</h4>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="payments_settings.enabled_payment">
                            <Form.Label>Payment service provider</Form.Label>
                            <Form.Control
                                as="select"
                                name="payments_settings.enabled_payment"
                                value={
                                    merchant?.payments_settings
                                        ?.enabled_payment || ''
                                }
                                onChange={handleChangeMerchantField}
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Choose...</option>
                                <option key="trumo" value="trumo">
                                    Trumo
                                </option>
                                <option key="stripe" value="stripe">
                                    Stripe
                                </option>
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Active payment service provider
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="settings.photos_upload_url">
                            <Form.Label>Photos upload URL</Form.Label>
                            <Form.Control
                                name="settings.photos_upload_url"
                                type="text"
                                value={merchant?.settings?.photos_upload_url}
                                onChange={handleChangeMerchantField}
                                placeholder="https://example.com/upload/photos"
                            />
                            <Form.Text className="text-muted">
                                the page where customer can upload images of the
                                product
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h4 className="mt-3">Trumo payments settings</h4>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="payments_settings.trumo.merchant">
                            <Form.Label>Merchant ID</Form.Label>
                            <Form.Control
                                name="payments_settings.trumo.merchant"
                                type="text"
                                value={
                                    merchant?.payments_settings?.trumo?.merchant
                                }
                                onChange={handleChangeMerchantField}
                                placeholder="6221dafc07a82cc26c05cf74"
                            />
                            <Form.Text className="text-muted">
                                Merchant ID on Trumo platform
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="payments_settings.trumo.merchant_api_key">
                            <Form.Label>Merchant API_KEY</Form.Label>
                            <Form.Control
                                name="payments_settings.trumo.merchant_api_key"
                                type="text"
                                value={
                                    merchant?.payments_settings?.trumo
                                        ?.merchant_api_key
                                }
                                onChange={handleChangeMerchantField}
                                placeholder="AGkvnYkzFCHUVWTSW97tIN4cZ2uyGsDBAOQqoLKn"
                            />
                            <Form.Text className="text-muted">
                                Merchant API_KEY on Trumo platform
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="payments_settings.trumo.identity_client">
                            <Form.Label>
                                Identity client for Trumo app
                            </Form.Label>
                            <Form.Control
                                name="payments_settings.trumo.identity_client"
                                type="text"
                                value={
                                    merchant?.payments_settings?.trumo
                                        ?.identity_client
                                }
                                onChange={handleChangeMerchantField}
                                placeholder="example_app;web;3.0"
                            />
                            <Form.Text className="text-muted">
                                Merchant API_KEY on Trumo platform
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="payments_settings.trumo.trumo_public_key">
                            <Form.Label>Public key</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="-----BEGIN PUBLIC KEY-----..."
                                name="payments_settings.trumo.trumo_public_key"
                                value={
                                    merchant?.payments_settings?.trumo
                                        ?.trumo_public_key
                                }
                                onChange={handleChangeMerchantField}
                            />
                            <Form.Text className="text-muted">
                                generated Merchant ID sha256 public key
                            </Form.Text>

                            <Form.Text className="text-muted">
                                Trumo platform sha256 public key
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="payments_settings.trumo.trumo_redirect_url">
                            <Form.Label>Redirect URL</Form.Label>
                            <Form.Control
                                name="payments_settings.trumo.trumo_redirect_url"
                                type="text"
                                value={
                                    merchant?.payments_settings?.trumo
                                        ?.trumo_redirect_url
                                }
                                onChange={handleChangeMerchantField}
                                placeholder="https://website.com/payment/trumo/"
                            />
                            <Form.Text className="text-muted">
                                Page with iFrame to render trumo widget for
                                payment
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h4 className="mt-3">Stripe payments settings</h4>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="stripe_payment_settings.secret_key">
                            <Form.Label>Secret Key</Form.Label>
                            <Form.Control
                                name="stripe_payment_settings.secret_key"
                                type="text"
                                value={
                                    merchant?.stripe_payment_settings?.secret_key
                                }
                                onChange={handleChangeMerchantField}
                                placeholder="AGkvnYkzFCHUVWTSW97tIN4cZ2uyGsDBAOQqoLKn"
                            />
                            <Form.Text className="text-muted">
                                Merchant API_KEY on Stripe platform
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="stripe_payment_settings.publishable_key">
                            <Form.Label>Publishable key</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="-----BEGIN PUBLIC KEY-----..."
                                name="stripe_payment_settings.publishable_key"
                                value={
                                    merchant?.stripe_payment_settings?.publishable_key
                                }
                                onChange={handleChangeMerchantField}
                            />
                            <Form.Text className="text-muted">
                                generated Merchant ID sha256 public key
                            </Form.Text>
                            <Form.Text className="text-muted">
                                Stripe platform sha256 public key
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group controlId="stripe_payment_settings.return_url">
                            <Form.Label>Redirect URL</Form.Label>
                            <Form.Control
                                name="stripe_payment_settings.return_url"
                                type="text"
                                value={
                                    merchant?.stripe_payment_settings?.return_url
                                }
                                onChange={handleChangeMerchantField}
                                placeholder="https://website.com/payment/stripe/"
                            />
                            <Form.Text className="text-muted">
                                Page with iFrame to render stripe widget for
                                payment
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h4 className="mt-3">Additional info</h4>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col md={6}>Wallet balance</Col>
                            <Col md={6}>
                                {merchant?.Merchant_balance ? (
                                    <>
                                        {renderCurrency(merchant?.currency)}
                                        {setDecimals(
                                            merchant?.Merchant_balance
                                        )}
                                    </>
                                ) : (
                                    <>&mdash;</>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>IBAN</Col>
                            <Col md={6}>
                                {merchant?.IBAN_Number || <>&mdash;</>}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Check
                            className="mt-3"
                            type="switch"
                            id="settings.enable_roles_mapper"
                            name="settings.enable_roles_mapper"
                            label="Enable roles mapper"
                            checked={!!merchant?.settings?.enable_roles_mapper}
                            onChange={(e) =>
                                handleChangeMerchantField({
                                    target: {
                                        name: 'settings.enable_roles_mapper',
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <RolesMapperForm
                            className={cn('mt-3')}
                            mappings={merchant?.settings?.roles_mapper || []}
                            setMappings={(val) =>
                                handleChangeMerchantField({
                                    target: {
                                        name: 'settings.roles_mapper',
                                        value: val,
                                    },
                                })
                            }
                            label={
                                'Select which roles should be possible to add using initial one'
                            }
                            subheader="Role from should not be repeated, same keys will be overriden"
                            onRemove={handleRemoveRoleMapper}
                        />
                    </Col>
                    <Col xs={12}>
                        <Button
                            className={cn('float-right')}
                            variant="outline-primary"
                            onClick={handleAddRoleMapper}
                        >
                            Add
                        </Button>
                    </Col>
                </Row>

                <Row className={cn('mt-3')}>
                    <Col
                        xs={12}
                        className={cn(
                            'd-flex',
                            'justify-content-end',
                            'align-items-center'
                        )}
                    >
                        <Button
                            type="submit"
                            variant="primary"
                            className={cn('')}
                        >
                            Submit
                        </Button>

                        {merchantInfo?._id ? (
                            <Button
                                disabled={merchantInfo?.is_admin}
                                type="button"
                                variant="danger"
                                onClick={handleInitDelete}
                                className={cn('ml-3')}
                            >
                                {merchantInfo?.is_admin
                                    ? 'Cannot remove admin merchant'
                                    : 'Delete'}
                            </Button>
                        ) : null}
                    </Col>
                </Row>
            </Form>

            <ConfirmModal
                title="Delete merchant"
                text="Are you sure you want to delete this merchant?"
                confirmLabel="Yes"
                cancelLabel="No"
                show={deleteInitiated}
                handleClose={(_) => setDeleteInitiated(false)}
                confirmAction={handleConfirmDelete}
            />
        </Container>
    );
};

export default MerchantInfoForm;
