import { Link } from 'react-router-dom';
import { OAUTH_URL } from '../config';

const LandingPage = () => {
  return (
    <div className="container h-100 d-flex flex-column justify-content-center">
      <div className="row bg-light">
        <div className="col bg-white shadow text-center rounded p-3">
          <header>
            <h2 className="my-3">Welcome to Honkio Admin panel</h2>
          </header>
          <main>
            <h1>
              Please <a href={`${OAUTH_URL}`}>sign in</a>
            </h1>
          </main>
          <footer>
            <h5 className="text-right text-muted">
              or you could <Link to="/login">sign up</Link>, if you haven't done
              it yet
            </h5>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
