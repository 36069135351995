import React, { useState, useEffect, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import languages from '../../../__fixtures__/languages';
import {
    Form,
    Row,
    Col,
    Container,
    Button,
    InputGroup,
    ListGroup,
    Image,
} from 'react-bootstrap';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import cn from 'classnames';
import DataTable from 'react-data-table-component';

import getMerchantList from '../../../api/utils/merchant/getMerchantList';
import ListStrInput from '../../common/ListStrInput';
import Icon from '../../UI/Icon';
import CustomRegReqList from './CustomRegReqList';
import { getFileList } from '../../../api/utils/files/getFileList';
import RegStatusesForm from './RegStatusesForm';
import getAllowedRegStatuses from '../../../api/utils/orderModel/getAllowedRegStatuses';
import getAdminRolesList from '../../../api/utils/role/getAdminRolesList';
import SpecialActionsInput from '../SpecialActions/SpecialActionsInput';
import SpecActionModal from '../SpecialActions/SpecActionModal';
import listSpecialActions from '../../../api/utils/app/specialActions/listSpecialActions';
import listSpecialTriggers from '../../../api/utils/app/specialActions/listSpecialTriggers';

const AppEditForm = ({
    appInfo = {},
    setAppInfo = () => {},
    onSubmit = () => {},
    language = 'en',
    setLanguage = () => {},
    editorData = '',
    setEditorData = () => {},
    existingCustomRegReq = [],
}) => {
    const [app, setApp] = useState(appInfo);

    const [merchantsList, setMerchantsList] = useState([]);

    // Custom merchant registration requirements
    const [customRegReq, setCustomRegReq] = useState(existingCustomRegReq);

    // const [fileList, setFileList] = useState([]);

    const [merchantRegStatuses, setMerchantRegStatuses] = useState([]);

    const [roles, setRoles] = useState(null);

    const [rolesOptions, setRolesOptions] = useState([
        {
            label: 'None',
            value: -1,
        },
    ]);

    const [appSpecialActions, setAppSpecialActions] = useState([]);
    const [editSpecAction, setEditSpecAction] = useState(null);
    const [showSpecActionModal, setShowSpecActionModal] = useState(false);

    const [availableScripts, setAvailableScripts] = useState([]);
    const [availableTriggers, setAvailableTriggers] = useState([]);

    const fetchSpecialActionsList = async () => {
        const errorHandler = (error) => {
            setAvailableScripts([]);
            NotificationManager.error(error.description, 'Error', 4000);
        };
        const resultHandler = (data) => {
            if (data && data?.status == 'accept') {
                setAvailableScripts(data?.scripts);
            }
        };
        try {
            const res = await listSpecialActions({}, errorHandler);
            resultHandler(res);
        } catch (error) {
            setAvailableScripts([]);
            console.error('Error loading special actions list: ', error);
            errorHandler(error);
        }
    };

    const fetchSpecialActionsTriggersList = async () => {
        const errorHandler = (error) => {
            setAvailableTriggers([]);
            NotificationManager.error(error.description, 'Error', 4000);
        };
        const resultHandler = (data) => {
            if (data && data?.status == 'accept') {
                setAvailableTriggers(data?.action_triggers);
            }
        };
        try {
            const res = await listSpecialTriggers({}, errorHandler);
            resultHandler(res);
        } catch (error) {
            setAvailableTriggers([]);
            console.error(
                'Error loading special actions triggers list: ',
                error
            );
            errorHandler(error);
        }
    };

    useEffect(() => {
        fetchSpecialActionsList();
        fetchSpecialActionsTriggersList();
    }, []);

    useEffect(() => {
        if (roles?.length) {
            const defaultOpt = {
                label: 'None',
                value: -1,
            };

            const newRoles = roles.map((item) => ({
                label:
                    item?.display_name || item?.display_name__en || item.name,
                value: item._id,
            }));
            newRoles.push(defaultOpt);
            setRolesOptions(newRoles);
        }
    }, [roles]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = _.cloneDeep(app);

        // update data here if needed
        if (!_.isEmpty(customRegReq)) {
            data.settings.merchant_registration_requirements = [
                ...data.settings.merchant_registration_requirements,
                ...customRegReq,
            ];
        }

        // if (!_.isEmpty(appSpecialActions)) {
        data.special_actions = appSpecialActions;
        // }

        onSubmit(data);
    };

    const handleChangeAppField = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setApp((prev) => {
            const newAppInfo = _.cloneDeep(prev);
            _.set(newAppInfo, name, value);
            return newAppInfo;
        });
    };

    useEffect(() => {
        console.log('app changed: ', app);
    }, [app]);

    // useEffect(() => {
    //     console.log('fileList == ', fileList);
    // }, [fileList]);

    const fetchMerchants = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setMerchantsList(data.merchants);
            } else {
                NotificationManager.error('Error');
                setMerchantsList([]);
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setMerchantsList([]);
        };

        const options = { query_application: appInfo?._id || '' };
        try {
            const res = await getMerchantList(options, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log('Error getting merchants: ', error);
            setMerchantsList([]);
        }
    };

    useEffect(() => {
        if (app._id) fetchMerchants();
    }, [app._id]);

    // const fetchAssetFiles = async () => {
    //     const resultHandler = (data) => {
    //         if (data && data.status === 'accept') {
    //             setFileList(data.files);
    //         } else {
    //             NotificationManager.error('Error');
    //         }
    //     };

    //     const errorHandler = (error) => {
    //         NotificationManager.error(error.description, 'Error', 4000);
    //         setFileList([]);
    //     };

    //     try {
    //         const res = await getFileList(
    //             { type: 'image', object: appInfo?._id },
    //             errorHandler
    //         );
    //         resultHandler(res);
    //     } catch (error) {
    //         setFileList([]);
    //     }
    // };

    // useEffect(() => {
    //     fetchAssetFiles();
    // }, [appInfo]);

    const fetchRegStatuses = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setMerchantRegStatuses(data.allowed_merchant_statuses);
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setMerchantRegStatuses([]);
        };

        try {
            const res = await getAllowedRegStatuses({}, errorHandler);
            resultHandler(res);
        } catch (error) {
            console.log('unexpected error when fetching reg statuses: ', error);
            setMerchantRegStatuses([]);
        }
    };

    useEffect(() => {
        fetchRegStatuses();
    }, []);

    const fetchRoles = async (appId) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.roles) {
                setRoles(data.roles);
            } else {
                setRoles([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setRoles([]);
        };

        try {
            const res = await getAdminRolesList(
                { app: app._id, merchant: app.admin_merchant },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('unexpected error while fetching roles: ', error);
            setRoles([]);
        }
    };

    useEffect(() => {
        if (app?._id) fetchRoles();
    }, [app?._id]);

    const onRequirementsClick = (data) => {
        const regInfo = app.settings.merchant_registration_requirements;
        const newInfo = regInfo.map((e) => {
            if (e.id === data.id) {
                return {
                    id: data.id,
                    required: data.required,
                    name: data.name,
                    mandatory: data.mandatory,
                    type: data.type,
                };
            } else {
                return e;
            }
        });

        const newApp = _.cloneDeep(app);
        newApp.settings.merchant_registration_requirements = newInfo;

        setApp(newApp);
    };

    const columns = useMemo(() => [
        {
            name: 'Is used',
            grow: 1,
            cell: (row) => {
                const iconName = row.required
                    ? 'bi-check2-square'
                    : 'bi-square';
                return (
                    <div
                        onClick={() => {
                            const new_row = Object.assign({}, row, {
                                required: !row.required,
                                mandatory: false,
                            });
                            return onRequirementsClick(new_row);
                        }}
                        data-tip
                        data-for="selectTip"
                    >
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                );
            },
        },
        {
            name: 'Information',
            grow: 3,
            cell: (e) => {
                return e.name;
            },
        },
        {
            name: 'Is mandatory',
            grow: 1,
            cell: (row) => {
                const iconName = row.mandatory
                    ? 'bi-check2-square'
                    : 'bi-square';
                return (
                    <div
                        onClick={() => {
                            const new_row = Object.assign({}, row, {
                                required: true,
                                mandatory: !row.mandatory,
                            });
                            return onRequirementsClick(new_row);
                        }}
                        data-tip
                        data-for="selectTip"
                    >
                        <Icon iconName={`btn ${iconName}`} />
                    </div>
                );
            },
        },
    ]);

    useEffect(() => {
        if (app?.settings) {
            setAppSpecialActions(app?.special_actions);
        }
    }, [app]);

    const handleAddSpecialAction = (specAction) => {
        if (_.has(specAction, 'idx')) {
            // Action is being edited
            setAppSpecialActions((prev) => {
                let updatedActions = _.cloneDeep(prev);
                const idx = specAction.idx;
                delete specAction.idx;
                updatedActions[idx] = specAction;
                return updatedActions;
            });
            setEditSpecAction(null);
            setShowSpecActionModal(false);
        } else {
            // Add new action
            setAppSpecialActions((prev) => [...prev, specAction]);
            setEditSpecAction(null);
            setShowSpecActionModal(false);
        }
    };

    const handleEditSpecAction = (idx) => {
        try {
            if (appSpecialActions?.length)
                setEditSpecAction({ ...appSpecialActions?.[idx], idx });
        } catch (error) {
            console.error('error edit spec action', error);
        }
        setShowSpecActionModal(true);
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Check
                            type="switch"
                            id="active"
                            name="active"
                            label="Active"
                            checked={!!app.active}
                            onChange={(e) =>
                                handleChangeAppField({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>Main settings</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                type="text"
                                value={app.name}
                                onChange={handleChangeAppField}
                                placeholder="Application name"
                                required
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="classname">
                            <Form.Label>Package (class)</Form.Label>
                            <Form.Control
                                name="classname"
                                type="text"
                                value={app.classname}
                                onChange={handleChangeAppField}
                                placeholder="honkio"
                                required
                            />
                            <Form.Text className="text-muted">
                                Unique string, which will be used to access API
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="admin_merchant">
                            <Form.Label>Owner merchant</Form.Label>
                            <Form.Control
                                as="select"
                                name="admin_merchant"
                                value={app.admin_merchant}
                                onChange={handleChangeAppField}
                                className={cn('custom-select', 'crsr')}
                                required
                            >
                                <option value="">Select merchant</option>
                                {merchantsList.map((opt, i) => (
                                    <option
                                        key={`app-type-option-${i}-${opt?._id}`}
                                        value={opt?._id}
                                    >
                                        {opt?.name || opt?.str_name}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Primary merchant of the application
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                {/* <Row>
                    <Col xs={12} md={6}>
                        <Image
                            src={
                                app?.settings?.str_logo_large ||
                                'https://placehold.co/600x400'
                            }
                            rounded
                            fluid
                            width="100%"
                        />
                        <Form.Group controlId="admin_merchant">
                            <Form.Label>Logo large</Form.Label>
                            <Form.Control
                                as="select"
                                name="settings.str_logo_large"
                                value={app.settings.str_logo_large}
                                onChange={handleChangeAppField}
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Select logo</option>
                                {fileList.map((opt, i) => (
                                    <option
                                        key={`app-type-option-${i}-${opt?._id}`}
                                        value={opt?.url}
                                    >
                                        {`${opt?.display_name}.${opt.extension}`}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Large logo. You can upload image in the gallery
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Image
                            src={
                                app?.settings?.str_logo_small ||
                                'https://placehold.co/600x400'
                            }
                            rounded
                            fluid
                            width="100%"
                        />
                        <Form.Group controlId="admin_merchant">
                            <Form.Label>Logo small</Form.Label>
                            <Form.Control
                                as="select"
                                name="settings.str_logo_small"
                                value={app.settings.str_logo_small}
                                onChange={handleChangeAppField}
                                className={cn('custom-select', 'crsr')}
                            >
                                <option value="">Select logo</option>
                                {fileList.map((opt, i) => (
                                    <option
                                        key={`app-type-option-${i}-${opt?._id}`}
                                        value={opt?.url}
                                    >
                                        {`${opt?.display_name}.${opt.extension}`}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Small logo. You can upload image in the gallery
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row> */}

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>OAuth</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name="settings.str_password"
                                type="text"
                                value={app?.settings?.str_password || ''}
                                onChange={handleChangeAppField}
                                placeholder=""
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_oauth_id">
                            <Form.Label>OAuth ID</Form.Label>
                            <Form.Control
                                name="settings.str_oauth_id"
                                type="text"
                                value={app?.settings?.str_oauth_id || ''}
                                onChange={handleChangeAppField}
                                placeholder=""
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.str_oauth_secret">
                            <Form.Label>OAuth Secret</Form.Label>
                            <Form.Control
                                name="settings.str_oauth_secret"
                                type="text"
                                value={app?.settings?.str_oauth_secret || ''}
                                onChange={handleChangeAppField}
                                placeholder=""
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <ListStrInput
                            label="List of oauth allowed URI"
                            info="List of URI allowed to be used in user auth process"
                            name="settings.list_oauth_allowed_uri"
                            value={app.settings.list_oauth_allowed_uri}
                            onChange={(val) =>
                                handleChangeAppField({
                                    target: {
                                        name: 'settings.list_oauth_allowed_uri',
                                        value: val,
                                    },
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <ListStrInput
                            label="List of oauth redirect URI"
                            info="List of URI allowed to be used to redirect user after authentication"
                            name="settings.list_oauth_redirect_uri"
                            value={app.settings.list_oauth_redirect_uri}
                            onChange={(val) =>
                                handleChangeAppField({
                                    target: {
                                        name: 'settings.list_oauth_redirect_uri',
                                        value: val,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>Android settings</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.dict_min_version.android">
                            <Form.Label>Android min version</Form.Label>
                            <Form.Control
                                name="settings.dict_min_version.android"
                                type="text"
                                value={
                                    app?.settings?.dict_min_version?.android ||
                                    ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="2.0.1"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.android_push_api_key">
                            <Form.Label>Android push API key</Form.Label>
                            <Form.Control
                                name="settings.android_push_api_key"
                                type="text"
                                value={app?.settings.android_push_api_key || ''}
                                onChange={handleChangeAppField}
                                placeholder="hash"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>iOS settings</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Check
                            type="switch"
                            id="settings.bool_ios_sandbox"
                            name="settings.bool_ios_sandbox"
                            label="Sandbox mode"
                            checked={!!app?.settings?.bool_ios_sandbox}
                            onChange={(e) =>
                                handleChangeAppField({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.dict_min_version.ios">
                            <Form.Label>iOS min version</Form.Label>
                            <Form.Control
                                name="settings.dict_min_version.ios"
                                type="text"
                                value={
                                    app?.settings?.dict_min_version?.ios || ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="17.3"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.ios_api_key">
                            <Form.Label>iOS API key</Form.Label>
                            <Form.Control
                                name="settings.ios_api_key"
                                type="text"
                                value={app?.settings.ios_api_key || ''}
                                onChange={handleChangeAppField}
                                placeholder="hash"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="settings.ios_api_credentials">
                            <Form.Label>
                                iOS Credentials (Key id, Team Id, Package)
                            </Form.Label>
                            <Form.Control
                                name="settings.ios_api_credentials"
                                type="text"
                                value={app?.settings.ios_api_credentials || ''}
                                onChange={handleChangeAppField}
                                placeholder="text"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>Merchant registration requirements</h4>
                    </Col>
                </Row>

                <Form.Label>Merchant admin settings</Form.Label>

                <Row>
                    <Col xs={12}>
                        <DataTable
                            dense
                            striped
                            noDataComponent="No data"
                            highlightOnHover
                            data={[
                                'email',
                                'first_name',
                                'last_name',
                                'password',
                            ]
                                ?.map((ids) =>
                                    app?.settings?.merchant_registration_requirements?.find(
                                        (res) => res?.id === ids
                                    )
                                )
                                ?.filter(Boolean)}
                            columns={columns}
                        />
                    </Col>
                </Row>

                <Form.Label className="mt-4">Merchant settings</Form.Label>

                <Row>
                    <Col xs={12}>
                        <DataTable
                            dense
                            striped
                            noDataComponent="No data"
                            highlightOnHover
                            data={[
                                'business_id',
                                'merchant_name',
                                'address',
                                'city',
                                'zip',
                                'country',
                                'phone',
                            ]
                                ?.map((ids) =>
                                    app?.settings?.merchant_registration_requirements?.find(
                                        (res) => res?.id === ids
                                    )
                                )
                                ?.filter(Boolean)}
                            columns={columns}
                        />
                    </Col>
                </Row>

                <Form.Label className="mt-4">Others</Form.Label>

                <Row className="mb-3">
                    <Col xs={12}>
                        <DataTable
                            dense
                            striped
                            noDataComponent="No data"
                            highlightOnHover
                            data={app?.settings?.merchant_registration_requirements?.filter(
                                (role) =>
                                    ![
                                        'first_name',
                                        'last_name',
                                        'password',
                                        'merchant_name',
                                        'business_id',
                                        'address',
                                        'zip',
                                        'country',
                                        'city',
                                        'phone',
                                    ]?.includes(role?.id)
                            )}
                            columns={columns}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <CustomRegReqList
                            customRegReq={customRegReq}
                            setCustomRegReq={setCustomRegReq}
                        />
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col xs={12}>
                        <h4>Merchant registration groups</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <RegStatusesForm
                            key="merchant_reg_groups"
                            statuses={merchantRegStatuses}
                            rolesOptions={rolesOptions}
                            currentStatuses={
                                app?.settings?.merchant_status_groups
                            }
                            namePrefix={'settings.merchant_status_groups'}
                            onChange={handleChangeAppField}
                        />
                    </Col>
                </Row>

                <hr />

                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <h4>Special actions (python scripts)</h4>
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => setShowSpecActionModal(true)}
                    >
                        Add
                    </Button>
                </div>
                <SpecialActionsInput
                    triggers={availableTriggers}
                    scripts={availableScripts}
                    specialActions={appSpecialActions}
                    setAppSpecialActions={setAppSpecialActions}
                    editSpecAction={editSpecAction}
                    handleEditSpecialAction={handleEditSpecAction}
                />

                <hr />

                {/* <Row>
                    <Col xs={12}>
                        <h4>Payments</h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Form.Check
                            type="switch"
                            id="trumo_enabled"
                            name="payment_settings.trumo_enabled"
                            label="Enable Trumo payments"
                            checked={!!app?.payment_settings?.trumo_enabled}
                            onChange={(e) =>
                                handleChangeAppField({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="payment_settings.trumo_merchant_id">
                            <Form.Label>Trumo merchant ID</Form.Label>
                            <Form.Control
                                name="payment_settings.trumo_merchant_id"
                                type="text"
                                value={
                                    app?.payment_settings?.trumo_merchant_id ||
                                    ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="65b799a590aadf52381bb282"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="payment_settings.trumo_merchant_api_key">
                            <Form.Label>Trumo merchant API key</Form.Label>
                            <Form.Control
                                name="payment_settings.trumo_merchant_api_key"
                                type="text"
                                value={
                                    app?.payment_settings
                                        ?.trumo_merchant_api_key || ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="kgDqrLsJV5cdHz0PoQZ4aIAhspCdYRSOqG4qxI2l"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="payment_settings.trumo_success_url">
                            <Form.Label>Trumo success url</Form.Label>
                            <Form.Control
                                name="payment_settings.trumo_success_url"
                                type="text"
                                value={
                                    app?.payment_settings?.trumo_success_url ||
                                    ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="https://domain.com/success"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group controlId="payment_settings.trumo_fail_url">
                            <Form.Label>Trumo fail url</Form.Label>
                            <Form.Control
                                name="payment_settings.trumo_fail_url"
                                type="text"
                                value={
                                    app?.payment_settings?.trumo_fail_url || ''
                                }
                                onChange={handleChangeAppField}
                                placeholder="https://domain.com/failure"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <hr /> */}

                <h4>Terms of use (TOU)</h4>

                <Row>
                    <Col xs={12}>
                        <Form.Check
                            type="switch"
                            id="bool_plugin_tou"
                            name="settings.bool_plugin_tou"
                            label="Enable plugin"
                            checked={!!app?.settings.bool_plugin_tou}
                            onChange={(e) =>
                                handleChangeAppField({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Form.Group controlId="settings.float_tou_version">
                            <Form.Label>Version</Form.Label>
                            <Form.Control
                                name="settings.float_tou_version"
                                type="number"
                                min={0.0}
                                step={0.01}
                                value={app?.settings?.float_tou_version || ''}
                                onChange={handleChangeAppField}
                                placeholder="0.0"
                                required
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group controlId="settings.str_tou_url">
                            <Form.Label>TOU URL</Form.Label>
                            <Form.Control
                                name="settings.str_tou_url"
                                type="text"
                                value={app?.settings?.str_tou_url || ''}
                                onChange={handleChangeAppField}
                                placeholder="https://domain/tou"
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Form.Control
                            as="select"
                            name="language"
                            value={language}
                            onChange={(e) => {
                                console.log(
                                    'change language: value ==',
                                    e.target.value
                                );
                                setLanguage(e.target.value);
                            }}
                            className={cn('custom-select', 'crsr', 'mb-3')}
                        >
                            <option value="">Language</option>
                            {languages.map((opt, i) => (
                                <option
                                    key={`language-option-${i}-${opt?.value}`}
                                    value={opt?.value}
                                >
                                    {opt?.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>

                    <Col xs={12} className="mb-3">
                        <CKEditor
                            editor={ClassicEditor}
                            data={editorData}
                            onChange={(_, editor) => {
                                setEditorData(editor.getData());
                            }}
                        />
                    </Col>
                </Row>

                <Button
                    type="submit"
                    variant="primary"
                    className={cn('float-right')}
                >
                    Save changes
                </Button>
            </Form>

            <SpecActionModal
                specAction={editSpecAction}
                show={showSpecActionModal}
                onHide={() => {
                    setShowSpecActionModal(false);
                }}
                scripts={availableScripts}
                triggers={availableTriggers}
                specialActions={appSpecialActions}
                onSubmit={handleAddSpecialAction}
            />
        </div>
    );
};

export default AppEditForm;
