import { routesMerchant } from './routesMerchant';
import ApplicationCreate from '../../Application/ApplicationCreate';
import ApplicationInfo from '../../Application/ApplicationInfo';
import AppSelector from '../../AppSelector/AppSelector';
import AssetEdit from '../../Assets/AssetEdit';
import Assets from '../../Assets/Assets';
import AssetViewRaw from '../../Assets/AssetViewRaw';
import AssetStructureEdit from '../../AssetStructures/AssetStructureEdit';
import AssetStructureViewRaw from '../../AssetStructures/AssetStructureViewRaw';
import AssetTypes from '../../AssetType/AssetTypes';
import AssetTypesScheme from '../../AssetTypesScheme/AssetTypesScheme';
import AssetImages from '../../ImagesUpload/AssetImages';
import ChangePassword from '../../ConsumerPassword/ChangePassword';
import ConsumerEdit from '../../Consumers/ConsumerEdit';
import ConsumerList from '../../Consumers/ConsumerList';
import ConsumerTransactions from '../../ConsumerTransactions/ConsumerTransactions';
import DashboardComponent from '../../Dashboard/DashboardComponent';
import EventEdit from '../../Events/EventEdit';
import Events from '../../Events/Events';
import GreyListMenu from '../../GreyLists/GreyListMenu';
import GreyLists from '../../GreyLists/GreyLists';
import InvitationForRoleCreate from '../../Invitations/InvitationForRoleCreate';
import AllTransactionsMap from '../../Map/AllTransactionsMap';
import AppMessagesList from '../../Messages/AppMessagesList';
import MessageEditorById from '../../Messages/MessageEditorById';
import MysteriousComponent from '../../Mysterious/MysteriousComponent';
import OrderModelCreate from '../../OrderModel/OrderModelCreate';
import OrderModelEdit from '../../OrderModel/OrderModelEdit';
import OrderModelsList from '../../OrderModel/OrderModelsList';
import OrderEdit from '../../Orders/OrderEdit';
import Orders from '../../Orders/Orders';
import PasswordPolicy from '../../Password/PasswordPolicy';
import Products from '../../Products/Products';
import ProductTagEdit from '../../Products/ProductTagEdit';
import ProductTagList from '../../Products/ProductTagList';
import ProductTypeEdit from '../../Products/ProductTypeEdit';
import ProductTypeList from '../../Products/ProductTypeList';
import ProductReport from '../../Report/ProductReport';
import Reservations from '../../Reservation/Reservations';
import RoleEditor from '../../Roles/RoleEditor';
import RoleOverview from '../../Roles/RoleOverview';
import ScriptEdit from '../../Scripts/ScriptEdit';
import Scripts from '../../Scripts/Scripts';
import ServiceKeys from '../../ServiceKeys/ServiceKeys';
import NotifyMessageCreate from '../../Settings/NotifyMessage/NotifyMessageCreate';
import NotifyMessageEdit from '../../Settings/NotifyMessage/NotifyMessageEdit';
import NotifyMessageList from '../../Settings/NotifyMessage/NotifyMessageList';
import Settings from '../../Settings/Settings';
import ShopCreate from '../../ShopInfo/ShopCreate';
import ShopInfo from '../../ShopInfo/ShopInfo';
import Shops from '../../Shops/Shops';
import TransactionExternalDetails from '../../Transactions/Details/External';
import TransactionPaymentsDetails from '../../Transactions/Details/Payment';
import TransactionRegistrationDetails from '../../Transactions/Details/Register';
import TransactionRegisterMerchantDetails from '../../Transactions/Details/RegisterMerchant';
import TransactionTokenizationDetails from '../../Transactions/Details/Tokenization';
import TransactionExternalList from '../../Transactions/List/External/External';
import TransactionLoginList from '../../Transactions/List/Login/Login';
import TransactionPaymentList from '../../Transactions/List/Payment/Payment';
import OrderModelTemplatesList from '../../TemplateModels/OrderModelTemplates/OrderModelTemplatesList';
import OrderModelTemplateEdit from '../../TemplateModels/OrderModelTemplates/OrderModelTemplateEdit';
import OrderModelTemplateCreate from '../../TemplateModels/OrderModelTemplates/OrderModelTemplateCreate';
import UserCreate from '../../Users/UserCreate';
import UserEdit from '../../Users/UserEdit';
import Users from '../../Users/Users';
import VelocityRuleCreate from '../../VelocityRules/VelocityRuleCreate';
import VelocityRuleEdit from '../../VelocityRules/VelocityRuleEdit';
import VelocityRules from '../../VelocityRules/VelocityRules';
import AssetCreate from '../../Assets/AssetCreate';
import AssetStructures from '../../AssetStructures/AssetStructures';
import AssetTypeCreate from '../../AssetType/AssetTypeCreate';
import AssetTypeEdit from '../../AssetType/AssetTypeEdit';
import ProductCreate from '../../Products/ProductCreate';
import ProductEdit from '../../Products/ProductEdit';
import SalesReport from '../../Report/SalesReport';
import RoleCreate from '../../Roles/RoleCreate';
import ShopCloseDays from '../../ShopInfo/CloseDays/ShopCloseDays';
import ShopOpenTime from '../../ShopInfo/OpenTime/ShopOpenTime';
import MerchantRolesList from '../../Roles/MerchantRoleList';
import Chat from '../../Chat/Chat';
import Tools from '../../Tools/Tools';
import Room from '../../Chat/Room/Room';
import Transactions from '../../Transactions/List/User/Transactions';
import MerchantRegModelSelector from '../../RegistrationOrderModels/Merchant/MerchantRegModelSelector';
import UserRegModelSelector from '../../RegistrationOrderModels/User/UserRegModelSelector';
import AppLoginRules from '../../LoginRules/AppLoginRules';
import DocumentsApproval from '../../Registrations/Merchant/DocumentsApproval';
import DocsInfo from '../../Registrations/Merchant/DocsInfo';
import MerchantApproval from '../../Registrations/Merchant/MerchantApproval';
import SettingsInfo from '../../GlobalSettings/SettingsInfo';
import Aml from '../../AML/Aml';
import ManualVerify from '../../AML/ManualVerify';
import MerchantRegAppSettings from '../../MerchantRegistrationAppSettings/MerchantRegAppSettings';
import RegMerchants from '../../ShareholdersGraph/RegMerchants';
import UBOGraph from '../../ShareholdersGraph/UBOGraph';
import AccessRulesList from '../../AccessRules/List/AccessRulesList';
import CreateAccessRule from '../../AccessRules/CreateAccessRule';
import EditAccessRule from '../../AccessRules/EditAccessRule';
import EventCreate from '../../Events/EventCreate';
import OrderView from '../../Orders/OrderView';
import AssetStructureCreate from '../../AssetStructures/AssetStructureCreate';
import VoucherUsers from '../../Vouchers/VoucherUsers';
import ScriptCreate from '../../Scripts/ScriptCreate';
import AppSecrets from '../../AppSecrets/AppSecrets';
import JobsList from '../../Scheduler/JobsList/JobsList';
import Scheduler from '../../Scheduler/Scheduler';
import CreateJob from '../../Scheduler/Job/CreateJob';
import EditJob from '../../Scheduler/Job/EditJob';
import StatusesHistory from '../../Orders/Status/StatusesHistory';
import UserRegSettingsCreate from '../../UserRegistrationSettings/UserRegSettingsCreate';
import UserRegSettingsSelector from '../../UserRegistrationSettings/UserRegSettingsSelector';
import UserRegSettingsEdit from '../../UserRegistrationSettings/UserRegSettingsEdit';
import TemplateRolesList from '../../TemplateModels/TemplateRoles/TemplateRolesList';
import TemplateNotifymessagesList from '../../TemplateModels/TemplateNotifymessages/TemplateNotifymessagesList';
import TemplateRoleCreate from '../../TemplateModels/TemplateRoles/TemplateRoleCreate';
import TemplateRoleEdit from '../../TemplateModels/TemplateRoles/TemplateRoleEdit';
import TemplateNotifymessageCreate from '../../TemplateModels/TemplateNotifymessages/TemplateNotifymessageCreate';
import TemplateNotifymessageForm from '../../TemplateModels/TemplateNotifymessages/TemplateNotifymessageForm';
import TemplateNotifymessageEdit from '../../TemplateModels/TemplateNotifymessages/TemplateNotifymessageEdit';
import DebugPage from '../../Debug/DebugPage';
import WarehousesList from '../../Warehouses/WarehousesList';
import WarehouseCreate from '../../Warehouses/WarehouseCreate';
import WarehouseEdit from '../../Warehouses/WarehouseEdit';
import PushNotificationsConfig from '../../PushNotifications/PushNotificationsConfig';
import ProductTemplatesList from '../../TemplateModels/ProductTemplates/ProductTemplatesList';
import ProductTemplateEdit from '../../TemplateModels/ProductTemplates/ProductTemplateEdit';
import ProductTemplateCreate from '../../TemplateModels/ProductTemplates/ProductTemplateCreate';
import UserDocuments from '../../Users/UserDocuments';
import ViewFile from '../../Files/View/ViewFile';
import ImporterPage from '../../DataImporter/ImporterPage';
import Importer from '../../DataImporter/ColdCalls/Importer';
import TransactionRegisterList from '../../Transactions/List/Register/Register';
import TransactionTokenizationsList from '../../Transactions/List/Tokenization/Tokenization';
import TransactionLostPasswordDetails from '../../Transactions/Details/LostPassword';
import LostPasswordTransactionsList from '../../Transactions/List/LostPassword/LostPasswordPage';
import TransactionRegisterMerchantList from '../../Transactions/List/RegisterMerchant/RegisterMerchant';
import PSPsPayments from '../../Transactions/List/PSPS/PSPsPayments';
import VideoStreamingPage from '../../Debug/MinIO/VideoStreaming/VideoStreamingPage';
import VideoView from '../../Debug/MinIO/VideoStreaming/VideoView';
import Customers from '../../Customers/Customers';
import CustomerEdit from '../../Customers/CustomerEdit';
import CustomerCreate from '../../Customers/CustomerCreate';
import CustomerTypes from '../../CustomerTypes/CustomerTypes';
import CustomerTypeCreate from '../../CustomerTypes/CustomerTypeCreate';
import CustomerTypeEdit from '../../CustomerTypes/CustomerTypeEdit';

let routes = [
    { path: '/secret', label: 'API Emulator', component: MysteriousComponent },
    { path: '/dashboard', label: 'Dashboard', component: DashboardComponent },
    { path: '/apps', label: 'Applications', component: AppSelector },
    {
        path: '/shops',
        label: 'Shops',
        component: Shops,
    },
    {
        path: '/pointsOfSales',
        label: 'Shops',
        component: Shops,
    },
    {
        path: '/warehouses',
        label: 'Warehouses',
        component: WarehousesList,
    },
    {
        path: '/warehouses/create',
        label: 'Create warehouse',
        component: WarehouseCreate,
    },
    {
        path: '/warehouses/edit',
        label: 'Edit warehouse',
        component: WarehouseEdit,
    },
    {
        path: '/shop/edit',
        label: 'Shop Edit Form',
        component: ShopInfo,
        hidden: true,
    },
    {
        path: '/shop/create',
        label: 'Shop Create Form',
        component: ShopCreate,
        hidden: true,
    },
    {
        path: '/shop/opentime',
        label: 'Shop Open Time',
        component: ShopOpenTime,
        hidden: true,
    },
    {
        path: '/shop/closedays',
        label: 'Shop close days',
        component: ShopCloseDays,
        hidden: true,
    },
    {
        path: '/user/transactions',
        label: 'Consumer transactions',
        component: Transactions,
    },
    { path: '/users', label: 'Users', component: Users },
    { path: '/user/create', label: 'Users', component: UserCreate },
    {
        path: '/user/edit',
        label: 'User Edit',
        component: UserEdit,
        hidden: true,
    },
    {
        path: '/user/documents',
        label: 'User Documents',
        component: UserDocuments,
        hidden: true,
    },
    {
        path: '/user/document/view',
        label: "View user's document",
        component: ViewFile,
        hidden: true,
    },
    {
        path: '/transactions/register_merchant/:transactionID',
        label: 'Register merchant details',
        component: TransactionRegisterMerchantDetails,
    },

    {
        path: '/transactions/payments/:transactionID',
        label: 'Register merchant details',
        component: TransactionPaymentsDetails,
    },

    {
        path: '/transactions/registration/:transactionID',
        label: 'Register merchant details',
        component: TransactionRegistrationDetails,
    },

    {
        path: '/transactions/tokenization/:transactionID',
        label: 'Register merchant details',
        component: TransactionTokenizationDetails,
    },

    {
        path: '/transactions/lostpassword/:transactionID',
        label: 'Register merchant details',
        component: TransactionLostPasswordDetails,
    },
    {
        path: '/transactions/external/:transactionID',
        label: 'External details',
        component: TransactionExternalDetails,
    },
    {
        path: '/transactions/register_merchant',
        label: 'Register merchant',
        component: TransactionRegisterMerchantList,
    },
    {
        path: '/transactions/psps',
        label: 'PSPs',
        component: PSPsPayments,
    },
    {
        path: '/transactions/payments',
        label: 'Payment',
        component: TransactionPaymentList,
    },
    {
        path: '/transactions/login',
        label: 'Login',
        component: TransactionLoginList,
    },
    {
        path: '/transactions/external',
        label: 'External',
        component: TransactionExternalList,
    },
    {
        path: '/transactions/registration',
        label: 'Registration',
        component: TransactionRegisterList,
    },
    {
        path: '/transactions/tokenization',
        label: 'Tokenization',
        component: TransactionTokenizationsList,
    },
    {
        path: '/transactions/lostpassword',
        label: 'Lost password',
        component: LostPasswordTransactionsList,
    },
    { path: '/map', label: 'Map', component: AllTransactionsMap },
    {
        path: '/password',
        label: 'Security',
        component: PasswordPolicy,
        hidden: true,
    },
    {
        path: '/servicekeys',
        label: 'Service Keys',
        component: ServiceKeys,
        hidden: true,
    },
    {
        path: '/products/edit',
        label: 'Product Edit',
        component: ProductEdit,
        hidden: true,
    },
    {
        path: '/product/create',
        label: 'Product Create Form',
        component: ProductCreate,
        hidden: true,
    },
    {
        path: '/products/tags/add',
        label: 'Product Tag add',
        component: ProductTagEdit,
        hidden: true,
    },
    {
        path: '/products/tags',
        label: 'Product Tags',
        component: ProductTagList,
        hidden: true,
    },
    {
        path: '/products/types/add',
        label: 'Product Type add',
        component: ProductTypeEdit,
        hidden: true,
    },
    {
        path: '/products/types/edit',
        label: 'Product Type edit',
        component: ProductTypeEdit,
        hidden: true,
    },
    {
        path: '/products/types',
        label: 'Product Types',
        component: ProductTypeList,
        hidden: true,
    },
    { path: '/products', label: 'Products', component: Products, hidden: true },
    {
        path: '/scripts/edit',
        label: 'Script edit',
        component: ScriptEdit,
        hidden: true,
    },
    {
        path: '/scripts/create',
        label: 'Script create',
        component: ScriptCreate,
        hidden: true,
    },
    { path: '/scripts', label: 'Scripts', component: Scripts, hidden: true },
    {
        path: '/assets/edit',
        label: 'Edit asset',
        component: AssetEdit,
        hidden: true,
    },
    {
        path: '/assets/create',
        label: 'Create asset',
        component: AssetCreate,
        hidden: true,
    },
    {
        path: '/assets/viewraw',
        label: 'View asset',
        component: AssetViewRaw,
        hidden: true,
    },
    {
        path: '/asset/events',
        label: "Asset's events list",
        component: Events,
        hidden: true,
    },
    {
        path: '/assets',
        label: 'Assets',
        component: Assets,
        hidden: false,
    },
    {
        path: '/asset/type/edit',
        label: 'Asset type Edit',
        component: AssetTypeEdit,
        hidden: true,
    },
    {
        path: '/asset/type/create',
        label: 'Asset type Create Form',
        component: AssetTypeCreate,
        hidden: true,
    },
    {
        path: '/asset/types/scheme',
        label: 'Asset Types',
        component: AssetTypesScheme,
        hidden: true,
    },
    {
        path: '/asset/types',
        label: 'Asset Types',
        component: AssetTypes,
        hidden: true,
    },
    {
        path: '/assetstructures',
        label: 'Asset structures',
        component: AssetStructures,
        hidden: false,
    },
    {
        path: '/assetstructure/edit',
        label: 'Edit asset structure',
        component: AssetStructureEdit,
        hidden: true,
    },
    {
        path: '/assetstructure/create',
        label: 'Create asset structure',
        component: AssetStructureCreate,
        hidden: true,
    },
    {
        path: '/assetstructure/viewraw',
        label: 'View raw data',
        component: AssetStructureViewRaw,
        hidden: true,
    },
    {
        path: '/assetimages',
        label: 'Manage image assets',
        component: AssetImages,
        hidden: true,
    },
    {
        path: '/events/create',
        label: 'Create event',
        component: EventCreate,
        hidden: true,
    },
    {
        path: '/events/edit',
        label: 'Edit event',
        component: EventEdit,
        hidden: true,
    },
    {
        path: '/events',
        label: 'Events',
        component: Events,
        hidden: false,
    },
    {
        path: '/ordermodel/edit',
        label: 'Order Model Edit',
        component: OrderModelEdit,
        hidden: true,
    },
    {
        path: '/ordermodel/create',
        label: 'Order Model Create Form',
        component: OrderModelCreate,
        hidden: true,
    },
    {
        path: '/ordermodel',
        label: 'Order Models',
        component: OrderModelsList,
        hidden: true,
    },
    {
        path: '/orders/view',
        label: 'Inspect order',
        component: OrderView,
        hidden: true,
    },
    {
        path: '/orders/edit',
        label: 'Edit order',
        component: OrderEdit,
        hidden: true,
    },
    {
        path: '/orders',
        label: 'Orders',
        component: Orders,
        hidden: false,
    },
    {
        path: '/order/status/history',
        label: 'Statuses history',
        component: StatusesHistory,
    },
    {
        path: '/reservation',
        label: 'Reservation',
        component: Reservations,
        hidden: false,
    },
    {
        path: '/messages/create',
        label: 'Message Create Form',
        component: NotifyMessageCreate,
        hidden: true,
    },
    {
        path: '/messages/:notifyMessageID', // this route could be useful for testing purpuses
        label: 'Messages',
        component: NotifyMessageEdit,
        hidden: true,
    },
    {
        path: '/messages', // this route could be useful for testing purpuses
        label: 'Messages',
        component: NotifyMessageList,
        hidden: true,
    },
    {
        path: '/consumers',
        label: 'Consumer List',
        component: ConsumerList,
        hidden: true,
    },
    {
        path: '/consumer/edit',
        label: 'Consumer Edit',
        component: ConsumerEdit,
        hidden: true,
    },
    {
        path: '/customers',
        label: 'Customers List',
        component: Customers,
        hidden: true,
    },
    {
        path: '/customer/create',
        label: 'Customer Create',
        component: CustomerCreate,
        hidden: true,
    },
    {
        path: '/customer/edit',
        label: 'Customer Edit',
        component: CustomerEdit,
        hidden: true,
    },
    {
        path: '/customer/types',
        label: 'Customer Types List',
        component: CustomerTypes,
        hidden: true,
    },
    {
        path: '/customer/type/create',
        label: 'Customer Type Create',
        component: CustomerTypeCreate,
        hidden: true,
    },
    {
        path: '/customer/type/edit',
        label: 'Customer Type Edit',
        component: CustomerTypeEdit,
        hidden: true,
    },
    {
        path: '/app/roles',
        label: 'Application roles',
        component: MerchantRolesList,
        hidden: true,
    },
    {
        path: '/app/messages',
        label: 'Application Message List',
        component: AppMessagesList,
        hidden: true,
    },
    {
        path: '/app/edit',
        label: 'Application Info',
        component: ApplicationInfo,
        hidden: true,
    },
    {
        path: '/app/create',
        label: 'Application Info',
        component: ApplicationCreate,
        hidden: true,
    },
    {
        path: '/role/edit',
        label: 'Edit Role',
        component: RoleEditor,
        hidden: true,
    },
    {
        path: '/role/create/',
        label: 'Create Role w/o acl',
        component: RoleCreate,
        hidden: true,
    },
    {
        path: '/role/show',
        label: 'Role Overview',
        component: RoleOverview,
        hidden: true,
    },
    {
        path: '/invitation/create',
        label: 'Invitation Create Form',
        component: InvitationForRoleCreate,
        hidden: true,
    },
    {
        path: '/message/edit',
        label: 'Message Edit',
        component: MessageEditorById,
        hidden: true,
    },
    {
        path: '/greylists/greylist',
        label: 'Greylists',
        component: GreyLists,
        hidden: true,
    },
    {
        path: '/greylists',
        label: 'Grey lists',
        component: GreyListMenu,
    },
    { path: '/tools', label: 'Tools', component: Tools },
    { path: '/chat', label: 'Chat', component: Chat },
    { path: '/chat-room', label: 'Chat room', component: Room },
    { path: '/settings', label: 'Settings', component: Settings },
    {
        path: '/consumertransactions',
        label: 'Consumer Transactions',
        component: ConsumerTransactions,
    },
    {
        path: '/changePassword',
        label: 'Change password',
        component: ChangePassword,
    },
    {
        path: '/velocityRules',
        label: 'Velocity Rules',
        component: VelocityRules,
        hidden: true,
    },
    {
        path: '/velocityRule/edit',
        label: 'Velocity Rule Edit',
        component: VelocityRuleEdit,
        hidden: true,
    },
    {
        path: '/velocityRule/create',
        label: 'Velocity Rule Create Form',
        component: VelocityRuleCreate,
        hidden: true,
    },
    {
        path: '/product_report',
        label: 'Product report',
        component: ProductReport,
        hidden: true,
    },
    {
        path: '/sales_report',
        label: 'Sales report',
        component: SalesReport,
        hidden: true,
    },
    {
        path: '/registration_models/merchant',
        label: 'Merchant registration models',
        component: MerchantRegModelSelector,
        hidden: true,
    },
    {
        path: '/registration_models/user',
        label: 'User registration models',
        component: UserRegModelSelector,
        hidden: true,
    },
    {
        path: '/registrations/merchant/documents_review',
        label: 'Merchant registration docs manual review',
        component: DocumentsApproval,
        hidden: true,
    },
    {
        path: '/registrations/merchant/document_review',
        label: 'Merchant registration documents review',
        component: DocsInfo,
        hidden: true,
    },
    {
        path: '/registrations/merchant/approval',
        label: 'Approve or reject merchant registrations',
        component: MerchantApproval,
        hidden: true,
    },
    {
        path: '/login_rules',
        label: 'Login rules',
        component: AppLoginRules,
        hidden: true,
    },
    {
        path: '/global_settings',
        label: 'Global settings',
        component: SettingsInfo,
        hidden: true,
    },
    {
        path: '/aml',
        label: 'AML',
        component: Aml,
        hidden: true,
    },
    {
        path: '/greylist_manualverify',
        label: 'Greylist manual verification',
        component: ManualVerify,
        hidden: true,
    },
    {
        path: '/merchant_register_app',
        label: 'Merchant register app',
        component: MerchantRegAppSettings,
        hidden: true,
    },
    {
        path: '/registrations/merchant/review_shareholders',
        label: 'Review shareholders',
        component: RegMerchants,
        hidden: true,
    },
    {
        path: '/registrations/merchant/ubo_graph',
        label: 'UBO graph',
        component: UBOGraph,
        hidden: true,
    },
    {
        path: '/accessrules/list',
        label: 'Access rules',
        component: AccessRulesList,
        hidden: true,
    },
    {
        path: '/accessrules/create',
        label: 'Create access rule',
        component: CreateAccessRule,
        hidden: true,
    },
    {
        path: '/accessrules/edit',
        label: 'Edit access rule',
        component: EditAccessRule,
        hidden: true,
    },
    {
        path: '/vouchers',
        label: 'Vouchers',
        component: VoucherUsers,
        hidden: true,
    },
    {
        path: '/app_secrets',
        label: 'App secrets',
        component: AppSecrets,
        hidden: true,
    },
    // Scripts scheduler
    {
        path: '/scheduler',
        label: 'Scheduler',
        component: Scheduler,
        hidden: true,
    },
    {
        path: '/scheduler/jobs-list',
        label: 'Scheduled jobs',
        component: JobsList,
        hidden: true,
    },
    {
        path: '/scheduler/job/create',
        label: 'Create job',
        component: CreateJob,
        hidden: true,
    },
    {
        path: '/scheduler/job/edit',
        label: 'Edit job',
        component: EditJob,
        hidden: true,
    },
    {
        path: '/merchant/user/registration-settings',
        label: 'User registration settings',
        component: UserRegSettingsSelector,
        hidden: true,
    },
    {
        path: '/merchant/user/registration-settings/create',
        label: 'User registration settings create',
        component: UserRegSettingsCreate,
        hidden: true,
    },
    {
        path: '/merchant/user/registration-settings/edit',
        label: 'User registration settings edit',
        component: UserRegSettingsEdit,
        hidden: true,
    },
    {
        path: '/template-models/roles',
        label: 'Server template role models',
        component: TemplateRolesList,
        hidden: true,
    },
    {
        path: '/template-models/roles/create',
        label: 'Create server template role model',
        component: TemplateRoleCreate,
        hidden: true,
    },
    {
        path: '/template-models/roles/edit',
        label: 'Edit server template role model',
        component: TemplateRoleEdit,
        hidden: true,
    },
    {
        path: '/template-models/notifymessages',
        label: 'Server template notifymessages models',
        component: TemplateNotifymessagesList,
        hidden: true,
    },
    {
        path: '/template-models/notifymessages/create',
        label: 'Create server template notify message model',
        component: TemplateNotifymessageCreate,
        hidden: true,
    },
    {
        path: '/template-models/notifymessages/edit',
        label: 'Edit server template notify message model',
        component: TemplateNotifymessageEdit,
        hidden: true,
    },
    {
        path: '/template-models/ordermodels',
        label: 'Server template order models',
        component: OrderModelTemplatesList,
        hidden: true,
    },
    {
        path: '/template-models/ordermodels/create',
        label: 'Create server template order model',
        component: OrderModelTemplateCreate,
        hidden: true,
    },
    {
        path: '/template-models/ordermodels/edit',
        label: 'Edit server template order model',
        component: OrderModelTemplateEdit,
        hidden: true,
    },
    {
        path: '/template-models/products',
        label: 'Template product models',
        component: ProductTemplatesList,
        hidden: true,
    },
    {
        path: '/template-models/products/create',
        label: 'Create template product',
        component: ProductTemplateCreate,
        hidden: true,
    },
    {
        path: '/template-models/products/edit',
        label: 'Edit template product',
        component: ProductTemplateEdit,
        hidden: true,
    },
    {
        path: '/push-notifications-config',
        label: 'Push notifications',
        component: PushNotificationsConfig,
    },
    {
        path: '/data-importer/',
        label: 'Data importer',
        component: ImporterPage,
        hidden: true,
    },
    {
        path: '/data-importer/cold-calls',
        label: 'Data importer cold calls',
        component: Importer,
        hidden: true,
    },
    // ----- Debug endpoints -----
    {
        path: '/debug',
        label: 'Debug page',
        component: DebugPage,
    },
    {
        path: '/debug/video-streaming',
        label: 'Debug video streaming',
        component: VideoStreamingPage,
    },
    {
        path: '/debug/video-streaming/view',
        label: 'Debug video streaming view',
        component: VideoView,
    },
];

routes = routes.concat(routesMerchant);
routes = routes.concat([
    {
        path: '/',
        label: 'Miss',
        component: () => {
            console.error('Miss route');
            return null;
        },
        hidden: true,
    },
]);

export default routes;
