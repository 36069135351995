// Oauth url in order to authenticate
export const OAUTH_TOKEN_URL =
    process.env.REACT_APP_OAUTH_TOKEN_URL || 'https://oauth.honkio.com/token';
export const OAUTH_REVOKE_TOKEN_URL =
    process.env.REACT_APP_OAUTH_REVOKE_TOKEN_URL ||
    'https://oauth.honkio.com/revoke';
export const OAUTH_REDIRECT_URL =
    process.env.REACT_APP_OAUTH_REDIRECT_URL ||
    'https://admin.honkio.com/authorized';
// export const OAUTH_REDIRECT_URL =
//   process.env.REACT_APP_OAUTH_REDIRECT_URL ||
//   'http://localhost:3000/authorized';

export const OAUTH_CLIENT_ID =
    process.env.REACT_APP_OAUTH_CLIENT_ID ||
    'Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP'; // local
export const OAUTH_CLIENT_SECRET =
    process.env.REACT_APP_OAUTH_CLIENT_SECRET ||
    'wnjlH9CyGjIhASgwzGyJ3e56ev3ZLzZfdhGnWFLX'; // local
export const OAUTH_URL =
    process.env.REACT_APP_OAUTH_URL ||
    [
        `https://oauth.honkio.com/authorize?platform=web`,
        `scope=email`,
        `response_type=code`,
        `client_id=Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP`,
        // `redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauthorized`,
        `redirect_uri=https%3A%2F%2Fadmin.honkio.com%2Fauthorized`,
    ].join('&');
export const REGISTRATION_URL =
    'https://oauth.honkio.com/register/?platform=web&response_type=code&client_id=Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP&redirect_uri=https://admin.honkio.com/authorized&scope=email';
export const MERCHANT_REGISTER_URL =
    'https://oauth.honkio.com/register/company/?platform=web&response_type=code&client_id=Gps5aNiYuuOICgGSBpMGxhMAdS64ZJz5EaaenUzP&redirect_uri=https://admin.honkio.com/authorized&scope=email';
// Internal API URL (Should be replaced in production)
export const INTERNAL_API_URL = 'https://rental-backend.honkio.com'; // replace in prod
export const GOOGLE_API_KEY = 'AIzaSyAK5T79Q9FhqPuYZ4cHl8rxFOX4Ck9KDHY';
// Main API URL
export const GOOGLE_ANALYTICS_ID = '';
export const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || 'https://admin.honkio.com/api/';
export const CONSUMER_URL =
    process.env.REACT_APP_CONSUMER_URL || 'https://consumer.2.honkio-dev.com/';
export const UPLOAD_URL =
    process.env.REACT_APP_CONSUMER_URL ||
    'https://media1.honkio.com/rental_dev/upload'; // fixme ??

// Authentication redirect path
export const REDIRECT_URL = '/dashboard';

// Checks each <TOKEN_EXPIRATION_CHECK_INTERVAL> milliseconds if token is expired
export const TOKEN_EXPIRATION_CHECK_INTERVAL = 1 * 60 * 1000; // 1 minute;
// Refresh token <TOKEN_REFRESH_MARGIN> minutes before actual expiration
export const TOKEN_REFRESH_MARGIN = 7; // 7 minutes

export const DEFAULT_APP_CLASSNAME = 'server';
export const SERVER_OWNER_ID = '424200000000000000000000';
export const SERVER_APP_ID = '454500000000000000000000';
